import React from "react";

import SectionHeader from "../components/home/SectionHeader";
import SectionContent from "../components/home/SectionContent";
import Map from "../components/map/Map";

import ICDown from '../images/ic_down.png'
import scrollToItem from "../utils/uiActions";
import {Link} from "react-router-dom";

// app images
import {appImages} from "../utils/appImages";
// import SectionDividerDark from "../components/SectionDividerDark";
import FlowbiteCarousel from "../components/home/HomeCarousel";

export const Home = () => {

    return (
        <>
            <div className={'flex flex-row justify-center'}>
                <div className={'flex flex-col md:mt-[10vh] mt-[5vh] w-[100%]'}>
                    <h1 className={'flex flex-row mt-2 justify-center text-white text-2xl md:text-4xl font-bold'}>
                        Flamingo Hill Tented Camp
                    </h1>
                    <h2 className={'text-white text-l font-light mt-2 justify-center italic flex flex-row'}>
                        "Your Dream Vacation at our boutique luxury camp"
                    </h2>
                </div>
            </div>


            <div className={'flex flex-row justify-center mt-5'}>
                <Link to={'/booking'} className={'justify-center text-white hover:font-bold text-3xl p-5  bg-[#be2332] rounded-[40px] align-middle'}>
                    BOOK NOW
                </Link>
            </div>

            <div className={'flex flex-row justify-center md:mt-[2vh] mt-[3vh] hover:cursor-pointer bg-transparent'}
                 onClick={(e) => scrollToItem("home-rooms")}>
                <img className={'w-[100px] h-[100px] animate-bounce bg-transparent'} src={ICDown} alt={'arrow down'}/>
            </div>

            <div id={'home-rooms'} className={'flex flex-col md:flex-row justify-center mt-10 bg-zinc-800 pt-[50px] md:pt-[100px] pb-[110px] md:px-10 px-2'}>

                <div className={'container'}>

                    <SectionHeader
                        title={'Explore our Lounge'}
                        description={'Enjoy the best of the best'}
                    />

                    <FlowbiteCarousel/>

                </div>

            </div>


            {/* <SectionDividerDark/> */}

            <div className={'flex flex-col md:flex-row justify-center pt-[50px] md:pt-[100px] bg-zinc-800 pb-[100px] md:px-10 px-2'}>

                <div className={'container'}>

                    <SectionHeader
                        title={'About Us'}
                        description={'Your Dream Vacation at our boutique luxury camp'}
                    />

                    <SectionContent>

                        <img className={'md:h-[50%] w-[100%] md:w-[50%] md:mr-[40px] object-cover rounded-lg bg-slate-100 md:drop-shadow-2xl'}
                             src={appImages.comfortable_friendly}
                             alt={'name'}/>

                        <div className={'flex flex-col h-full align-middle md:ml-[40px] md:w-[50%] md:h-[50%] mt-4 md:mt-0'}>

                            <h1 className={'text-white text-4xl font-bold'}>
                            A little more about us.
                            </h1>

                            <h2 className={'text-gray-500 text-2xl font-light mt-5'}>
                                The Flamingo Hill Camp is situated within the famous Lake Nakuru National Park, which lies within the Great Rift Valley of Kenya.<br/><br/>

                                Approximately 30 minutes by air or 3 hours by road from Nairobi. Nestled between the “honeymoon hills”, and set in beautifully landscaped grounds with natural foliage and a variety of indigenous African plants and flowers, Flamingo Hill Camp provides superb, tented accommodation.<br/><br/>

                                Flamingo Hill Camp is an eco-friendly establishment.<br/><br/>

                                Cosy, warm and inviting with a personal touch, Flamingo Hill Camp offers the traveler a home away from home!<br/><br/>  
                            </h2>
                        </div>

                    </SectionContent>

                </div>

            </div>

            {/* <SectionDividerDark/> */}

            <div className={'flex flex-col md:flex-row justify-center pt-[50px] md:pt-[100px] bg-zinc-800 pb-[100px] md:px-10'}>

                <div className={'container'}>

                    <SectionHeader
                        title={'Explore'}
                        description={'Visit your home in the wild'} />

                   <SectionContent>

                       <div className={'flex flex-col h-full align-middle md:px-0 md:mr-[40px] md:w-[50%] md:h-[50%]'}>

                           <h1 className={'text-white text-4xl font-bold'}>
                               A hidden world tucked away in the heart of Kenya
                           </h1>

                           <h2 className={'text-gray-500 text-2xl font-light mt-5'}>
                               In the cleft of the Great Rift Valley one of the earths most phenomenal geological features, ringed by shoals of extinct and dormant volcanoes and presided over by one of the largest craters in the world, lies Lake Nakuru National Park.<br/><br/>Part of the ancient landscape known as the “cradle of mankind”, the Park offers a wealth of history, unique wildlife, spectacular bird life and some of the most beautiful scenery in Africa
                           </h2>

                       </div>

                       <div className={'md:h-[50%] md:w-[50%] md:mr-40px md:px-0 mt-4 md:mt-0 grid grid-rows-4 grid-flow-col gap-2'}>

                           <img className={'object-cover row-span-4 col-span-2 h-[550px] rounded-lg hover:drop-shadow-2xl'}
                               src={'https://www.amboseliparkkenya.com/wp-content/uploads/2022/04/Lake-Nakuru-National-Park-2.jpg'}
                               alt={'name'}
                           />

                           <img className={'object-cover col-span-2 row-span-2 rounded-lg hover:drop-shadow-2xl h-[275px]'}
                                src={'https://www.kenya-experience.com/wp-content/gallery/lake-nakuru-nationalpark/Lake-Nakuru-NP_Maciej-Sudra_8.jpg'}
                                alt={'name'}
                           />

                           <img className={'object-cover row-span-2 col-span-2 rounded-lg hover:drop-shadow-2xl h-[275px]'}
                                src={'https://images.unsplash.com/photo-1623745493572-ef78d94249f3?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1476&q=80'}
                                alt={'name'}
                           />

                       </div>

                   </SectionContent>

                </div>

            </div>

            {/* <SectionDividerDark/> */}

            <div className={'bg-zinc-800 flex flex-row justify-center py-[50px] md:px-10'}>

                    <div className={'container px-10 md:px-0'}>

                        <div className={'flex flex-col md:flex-row justify-center items-center mt-[10px]'}>

                            <div className={'md:h-[35%] md:w-[50%] md:mr-40px grid grid-rows-4 grid-flow-col gap-2'}>

                                <img className={'object-cover row-span-4 col-span-2 h-[450px] rounded-lg hover:drop-shadow-2xl'}
                                     src={'https://flamingohillcamp.co.ke/wp-content/uploads/2019/07/Flamingo-Hill-Camp-22-768x512.jpg'}
                                     alt={'name'}
                                />

                                <img className={'object-cover col-span-2 row-span-2 rounded-lg hover:drop-shadow-2xl h-[225px] w-full'}
                                     src={'https://flamingohillcamp.co.ke/wp-content/uploads/2019/07/Flamingo-Hill-Camp-23-768x512.jpg'}
                                     alt={'name'}
                                />

                                <img className={'object-cover row-span-2 col-span-2 rounded-lg hover:drop-shadow-2xl h-[225px] w-full'}
                                     src={'https://ik.imgkit.net/3vlqs5axxjf/external/ik-seo/http://media.iceportal.com/140819/photos/71834137_XL/Nairobi-Serena-Hotel-Other.jpg?tr=w-780%2Ch-437%2Cfo-auto'}
                                     alt={'name'}
                                />

                            </div>

                            <div className={'flex flex-col md:h-[50%] md:w-[50%]  h-full align-middle md:ml-[20px] w-100 mt-4 md:mt-0'}>

                                <h1 className={'text-white text-4xl font-bold'}>
                                    Ritz' Gift Shop
                                </h1>


                                <h2 className={'text-gray-500 text-2xl font-medium mt-5'}>
                                    Our on site Boutique Gift shop offers unique and authentic hand-made gift items made by different communities in Kenya.
                                </h2>

                            </div>

                        </div>

                    </div>

                </div>

            {/* <SectionDividerDark/> */}

            <div className={'flex flex-col md:flex-row justify-center pt-[100px] bg-zinc-800 pb-[100px] md:px-10'}>
                <div className={'container'}>
                    <SectionHeader
                        title={'Visit Us'}
                        description={'Book a date with us and explore the possibilities'}
                    />

                    <SectionContent>
                        <Map className={'w-[100%] h-[35vh]'}/>
                    </SectionContent>

                </div>
            </div>

        </>
    )
}