import {Carousel} from "flowbite-react";
import { appImages } from "../../utils/appImages";
import { sliderTime } from "../../utils/uiActions";

export const FlowbiteCarousel = (): JSX.Element => {
    return(
        <div className="h-[450px] md:h-[550px] 2xl:h-[700px] mt-10">
            <Carousel slide={true} slideInterval={sliderTime}>
                
                <img
                    className={'object-cover h-[100%]'}
                    src={appImages.lounge_1}
                    alt="..."
                />

                <img
                    className={'object-cover h-[100%]'}
                    src={appImages.lounge_3}
                    alt="..."
                />

                <img
                    className={'object-cover h-[100%]'}
                    src={appImages.lounge_2}
                    alt="..."
                />

                <img 
                    className={''}
                    src={'https://flamingohillcamp.co.ke/wp-content/uploads/2019/07/Flamingo-Hill-Camp-9-1024x544.jpg'}
                    alt="..." 
                />

                <img
                    className={'object-cover h-[100%]'}
                    src={appImages.lounge_4}
                    alt="..."
                />

            </Carousel>
        </div>
    )
}

export default FlowbiteCarousel;