import React from "react";
import {Link} from "react-router-dom";
import SectionDividerDark from "../components/SectionDividerDark";
import {appImages} from "../utils/appImages";
import { Carousel } from "flowbite-react";
import { sliderTime } from "../utils/uiActions";

const Restaurants = (): JSX.Element => {
    return (
        <>
            <div className={'bg-transparent'}>

                <div className={'w-full flex flex-row mt-[70px] justify-center'}>
                    <h1 className={'text text-3xl font-extrabold text-white'}>Restaurant & Bar</h1>
                </div>

                <div className={'mt-2 mb-[70px] flex flex-row justify-center items-center'}>
                    <Link to={'/'} className={'text text-xl font-bold text-white mx-2 hover:cursor-pointer hover:text-[#be2332]'}>Home</Link>
                    <span className={'material-icons text-white font-bold mx-2'}>chevron_right</span>
                    <h2 className={'text text-xl font-bold text-white mx-2'}>Restaurant & Bar</h2>
                </div>

                <div className={'bg-zinc-800 flex flex-row justify-center py-[50px] md:px-10'}>

                    <div className={'container'}>

                        <div className={'flex md:flex-row flex-col justify-center items-center mt-14 md:px-10 px-2'}>
                            
                            <div className={'flex flex-col md:w-[50%] w-[100%] md:px-0'}>
                                <div className="h-[450px] md:h-[550px] 2xl:h-[700px] mt-10 md:px-10">
                                    <Carousel slide={true} slideInterval={sliderTime}>
                                        
                                    <img className={'object-cover rounded-lg drop-shadow-2xl h-full'}
                                         src={appImages.restaurant_1}
                                         alt={'name'}/>
                                    <img className={'object-cover rounded-lg drop-shadow-2xl h-full'}
                                         src={appImages.restaurant_5}
                                         alt={'name'}/>
                                    <img className={'object-cover rounded-lg drop-shadow-2xl h-full'}
                                         src={appImages.restaurant_6}
                                         alt={'name'}/>
                                    <img className={'object-cover rounded-lg drop-shadow-2xl h-full'}
                                         src={appImages.restaurant_7}
                                         alt={'name'}/>

                                    </Carousel>
                                </div>
                                {/* <div className={'grid grid-cols-2 grid-row-2 gap-4'}>
                                    
                                </div> */}
                            </div>

                            <div className={'flex flex-col md:w-[50%] w-[100%] mt-4 md:mt-0'}>

                            <div className={'flex flex-row justify-left mt-7'}>
                                <h1 className={'text text-4xl text-[#be2332] font-bold'}>Fantastic Meals Prepared With Excellence</h1>
                            </div>

                                <div className={'flex flex-row mt-10'}>
                                    <h1 className={'text text-white text-2xl font-medium'}>An Unforgettable Dining Experience</h1>
                                </div>

                                <div className={'flex flex-row mt-4'}>
                                    <h1 className={'text text-white text-lg font-light'}>
                                        Our experienced chefs will prepare meals to tantalize taste buds!
                                    </h1>
                                </div>

                                <div className={'flex flex-row'}>
                                    <ul className={'list-inside list-disc text-thin text-lg text-white'}>
                                        <li>Breakfast is served daily, between <strong className="text-rose-300">6.00am & 9.30am</strong></li>
                                        <li>Lunch is served between <strong className="text-rose-300">12.30 noon & 2.30pm</strong></li>
                                        <li>Dinner can be enjoyed between <strong className="text-rose-300">7.00pm & 9.30pm</strong></li>
                                    </ul>
                                </div>

                                <div className={'flex flex-row mt-4'}>
                                    <h1 className={'text text-white text-lg font-light'}>
                                        We also cater for any special dietary requests made by our guests. We pride ourselves on the food we serve our guests.<br/>
                                        QUALITY & EXCELLENCE is simply one way to describe what we offer!
                                    </h1>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <SectionDividerDark/>

                <div className={'bg-zinc-800 flex flex-row justify-center py-[50px] md:px-10'}>

                    <div className={'container'}>

                        <div className={'flex flex-col md:flex-row justify-center items-center mt-14 md:px-10 px-2'}>
                            <div className={'flex flex-col md:w-[50%] w-[100%] px-5'}>

                            <div className={'flex flex-row justify-left mt-7'}>
                                <h1 className={'text text-4xl text-[#be2332] font-bold text-center'}>Relax at our Bar</h1>
                            </div>

                                <div className={'flex flex-row mt-5'}>
                                   <p className="text-white text-lg font-thin">
                                    The bar is well stocked with local as well as imported beverages.
                                    Our guests are treated to the famous flamingo hill cocktails whilst relaxing in our comfortable and open Lounge with a view of the plains. 
                                    Recline, get pampered by our staff and enjoy an African sundowner!
                                    Socialise with other guests around a cosy fire in the evenings or simply get comfortable with a book from our Library.
                                   </p>
                                </div>

                            </div>
                            <div className={'flex flex-col md:w-[50%] w-[100%] mt-2 md:mt-0'}>
                                <div className="h-[450px] md:h-[550px] 2xl:h-[700px] mt-10 md:mt-0">
                                    <Carousel slide={true} slideInterval={sliderTime}>
                                        
                                    <img className={'object-cover rounded-lg drop-shadow-2xl h-full'}
                                         src={appImages.bar_3}
                                         alt={'name'}/>
                                    <img className={'object-cover rounded-lg drop-shadow-2xl h-full'}
                                         src={appImages.bar_1}
                                         alt={'name'}/>
                                    <img className={'object-cover rounded-lg drop-shadow-2xl h-full'}
                                         src={'https://flamingohillcamp.co.ke/wp-content/uploads/2019/07/DSC_0728-2-2-2-2-2-2-2-2-2-2-2.jpg'}
                                         alt={'name'}/>
                                    <img className={'object-cover rounded-lg drop-shadow-2xl h-full'}
                                         src={appImages.eggs}
                                         alt={'name'}/>

                                    <img className={'object-cover rounded-lg drop-shadow-2xl h-full'}
                                         src={'https://flamingohillcamp.co.ke/wp-content/uploads/2019/07/Flamingo-Hill-Camp-3.jpg'}
                                         alt={'name'}/>

                                    </Carousel>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>

            </div>

        </>
    )
}

export default Restaurants;