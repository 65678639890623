import { Carousel } from "flowbite-react";
import { Link } from "react-router-dom";
import { appImages } from "../utils/appImages";
import SectionDividerDark from "../components/SectionDividerDark";
import { sliderTime } from "../utils/uiActions";

const Spa = ():JSX.Element => {
    return (
        <>
            <div className="bg-transparent">
                
                <div className={'w-full flex flex-row mt-[70px] justify-center'}>
                    <h1 className={'text text-3xl font-extrabold text-white'}>Spa & Pool</h1>
                </div>

                <div className={'mt-2 mb-[70px] flex flex-row justify-center items-center'}>
                    <Link to={'/'} className={'text text-xl font-bold text-white mx-2 hover:cursor-pointer hover:text-[#be2332]'}>Home</Link>
                    <span className={'material-icons text-white font-bold mx-2'}>chevron_right</span>
                    <h2 className={'text text-xl font-bold text-white mx-2'}>Spa & Pool</h2>
                </div>


                <div className="bg-zinc-800 flex flex-row justify-center py-50px">

                    <div className="container">

                        <div className="flex flex-col justify-center items-center mt-[5vh] px-4 md:px-1">

                            <div className={'flex flex-col w-[100%]'}>
                                <h1 className={'text-[#be2332] text-4xl font-bold text-center'}>
                                    Relax at our pool.
                                </h1>

                                <h2 className={'text-white text-2xl font-medium mt-1 px-10 text-center'}>
                                    Our pool is the perfect place to relax and unwind.
                                </h2>
                            </div>
                            <div className={'flex flex-col w-[100%] mt-4 md:mb-10 h-[450px] md:h-[550px] 2xl:h-[700px]'}>
                                <Carousel slide={true} className="h-[100%]" slideInterval={sliderTime}>
                                    <img src={appImages.pool_2} alt="..." />
                                    <img src={appImages.pool_1} alt="..." />
                                    {/*<img src={'https://flamingohillcamp.co.ke/wp-content/uploads/2019/07/Section3-BG-1024x599.jpg'} alt="..." />*/}
                                </Carousel>
                            </div>




                        </div>

                        <SectionDividerDark/>

                        <div className="flex flex-col justify-center items-center mt-[5vh] px-4 md:px-1">

                            <div className={'flex flex-col w-[100%]'}>
                                <h1 className={'text-[#be2332] text-4xl font-bold text-center'}>
                                    Ease your tensions at our private spa
                                </h1>

                                <h2 className={'text-white text-2xl font-medium mt-1 px-10 text-center'}>
                                    Relax, unwind and get pampered
                                </h2>
                            </div>

                            <div className={'flex flex-col w-[100%] mt-4 mb-10 h-[450px] md:h-[550px] 2xl:h-[700px]'}>
                                <Carousel slide={true} className={""} slideInterval={sliderTime}>
                                    <img src={'https://flamingohillcamp.co.ke/wp-content/uploads/2019/07/Flamingo-Hill-Camp-18.jpg'} alt="..." />
                                    <img src={'https://flamingohillcamp.co.ke/wp-content/uploads/2019/07/Flamingo-Hill-Camp-29-1024x685.jpg'} alt="..." />
                                </Carousel>
                            </div>

                        </div>

                    </div>

                </div>


            </div>
        </>
    )
}

export default Spa;