import React, {useCallback, useState} from "react";
import {GoogleMap, Marker, useJsApiLoader} from "@react-google-maps/api";

const center = {
    lat: -0.3145116,
    lng: 36.0695723
};
const Map = ({className}: any) => {

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: process.env.REACT_APP_FIREBASE_API_KEY as string
    });
    // eslint-disable-next-line
    const [map, setMap] = useState<any>(null);
    const onLoad = useCallback(function callback(map: any){
        const bounds = new window.google.maps.LatLngBounds(center);
        map.fitBounds(bounds)
        setMap(map)
    },[]);
    const unMount = useCallback(function callback(map: any){
        setMap(null)
    }, []);

    return isLoaded ?(

        <GoogleMap
            mapContainerClassName={className}
            center={center}
            zoom={9}
            onLoad={onLoad}
            onUnmount={unMount}>

            <Marker position={center} />
        </GoogleMap>
    ): <></>
}

export default React.memo(Map)